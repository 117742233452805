import React, {ReactNode} from 'react'
import {Roles} from "../app/types/user";
import {
    RiFileList2Fill,
    RiHotelFill,
    RiMailOpenFill,
    RiParentFill,
    RiUserSettingsFill,
    RiUserVoiceFill
} from "react-icons/ri";
import { AiOutlineGlobal } from "react-icons/ai"

interface Route {
    path: string
    icon?: ReactNode
    title: string
    roles: Roles[]
    isHide?: boolean
}



export const Routes: Route[] = [
	{
		title: "Агенты",
		path: "/agents",
		roles: [Roles.ADMIN, Roles.MANAGER],
		icon: <RiUserVoiceFill />,
	},
	{
		title: "Объекты",
		path: "/facilities",
		roles: [Roles.ADMIN, Roles.MANAGER],
		icon: <RiHotelFill />,
	},
	{
		title: "Застройщики",
		path: "/developers",
		roles: [Roles.ADMIN, Roles.MANAGER],
		icon: <RiUserSettingsFill />,
	},
	{
		title: "Собственники",
		path: "/owners",
		roles: [Roles.ADMIN, Roles.MANAGER],
		icon: <RiParentFill />,
	},
	{
		title: "Контент менеджеры",
		path: "/managers",
		roles: [Roles.ADMIN],
		icon: <RiUserSettingsFill />,
	},
	{
		title: "Связаться с нами",
		path: "/contact",
		roles: [Roles.MANAGER],
		icon: <RiMailOpenFill />,
	},
	{
		title: "Новости",
		path: "/announcements",
		roles: [Roles.ADMIN, Roles.MANAGER],
		icon: <RiFileList2Fill />,
	},
	{
		title: "Шахматка",
		path: "/checkerboard",
		isHide: true,
		roles: [Roles.ADMIN, Roles.MANAGER],
	},
	{
		title: "Агентства",
		path: "/agencies",
		isHide: true,
		roles: [Roles.ADMIN, Roles.MANAGER],
	},
	{
        icon: <AiOutlineGlobal />,
		title: "Геосправочник",
		path: "/geoguid",
		roles: [Roles.ADMIN, Roles.MANAGER],
	},
]
