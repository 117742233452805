import type {FC} from 'react';
import {ReactNode, useEffect, useRef, useState} from 'react';
import NextLink from 'next/link';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import type {Theme} from '@mui/material';
import {Box, Button, Divider, Drawer, Typography, useMediaQuery} from '@mui/material';
import {Selector as SelectorIcon} from '@icons/selector';
import {Logo} from '../logo';
import {Scrollbar} from '../scrollbar';
import {DashboardSidebarSection} from './dashboard-sidebar-section';
import {useAuth} from "../../hooks/use-auth";
import {Roles} from "@app/types/user";
import {Routes} from "@utils/routes";

interface DashboardSidebarProps {
    onClose: () => void;
    open: boolean;
}

interface Item {
    title: string;
    children?: Item[];
    chip?: ReactNode;
    icon?: ReactNode;
    path?: string;
    roles?: Roles[]
}

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
    const {onClose, open} = props;
    const router = useRouter();
    const {t} = useTranslation();
    const auth = useAuth()
    const lgUp = useMediaQuery(
        (theme: Theme) => theme.breakpoints.up('lg'),
        {
            noSsr: true
        }
    );
    const organizationsRef = useRef<HTMLButtonElement | null>(null);
    const [openOrganizationsPopover, setOpenOrganizationsPopover] = useState<boolean>(false);

    const handlePathChange = () => {
        if (!router.isReady) {
            return;
        }

        if (open) {
            onClose?.();
        }
    };

    useEffect(
        handlePathChange,
        [router.isReady, router.asPath]
    );

    const handleOpenOrganizationsPopover = (): void => {
        setOpenOrganizationsPopover(true);
    };

    if (!auth.isAuthenticated) return null

    const content = (
        <>
            <Scrollbar
                sx={{
                    height: '100%',
                    '& .simplebar-content': {
                        height: '100%'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }}
                >
                    <div>
                        <Box sx={{p: 3}}>
                            <NextLink
                                href={'/facilities'}
                                passHref
                            >
                                <a>
                                    <Logo
                                        mode={auth.role}
                                        sx={{
                                            height: 42,
                                            width: 42
                                        }}
                                    />
                                </a>
                            </NextLink>
                        </Box>
                        <Box sx={{px: 2}}>
                            <Box
                                onClick={handleOpenOrganizationsPopover}
                                ref={organizationsRef}
                                sx={{
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.04)',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    px: 3,
                                    py: '11px',
                                    borderRadius: 1
                                }}
                            >
                                <div>
                                    <Typography
                                        color="inherit"
                                        variant="subtitle1"
                                    >
                                        {auth.user.name}
                                    </Typography>
                                    <Typography
                                        color="neutral.400"
                                        variant="body2"
                                    >
                                        {auth.role === Roles.ADMIN ? 'Админ панель' : 'Контент менеджер'}
                                    </Typography>
                                </div>
                                {
                                    auth.user.role === Roles.ADMIN &&
                                    <SelectorIcon
                                        onClick={() => auth.switchRole(auth.role === Roles.ADMIN ? Roles.MANAGER : Roles.ADMIN)}
                                        sx={{
                                            color: 'neutral.500',
                                            width: 14,
                                            height: 14
                                        }}
                                    />
                                }
                            </Box>
                        </Box>
                    </div>
                    <Divider
                        sx={{
                            borderColor: '#2D3748', // dark divider
                            my: 3
                        }}
                    />
                    <Box sx={{flexGrow: 1}}>
                        <DashboardSidebarSection
                            key={1}
                            path={router.asPath}
                            sx={{
                                mt: 2,
                                '& + &': {
                                    mt: 2
                                }
                            }}
                            title={''}
                            items={Routes.filter(r => r.roles.includes(auth.role) && !r.isHide)}
                        />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: '#2D3748'  // dark divider
                        }}
                    />
                    <Button onClick={() => auth.logout()}>Выход</Button>
                </Box>
            </Scrollbar>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        borderRightColor: 'divider',
                        borderRightStyle: 'solid',
                        borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
                        color: '#FFFFFF',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280
                }
            }}
            sx={{zIndex: (theme) => theme.zIndex.appBar + 100}}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};
