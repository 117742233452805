import type {FC, ReactNode} from 'react';
import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {useAuth} from "../hooks/use-auth";
import {toast} from "react-hot-toast";
import {Routes} from "../utils/routes";

interface RolesGuardProps {
    children: ReactNode;
}

export const RolesGuard: FC<RolesGuardProps> = (props) => {
    const { children } = props;
    const auth = useAuth();
    const router = useRouter();
    const [checked, setChecked] = useState(false);

    useEffect(
        () => {
            if (!router.isReady) {
                return;
            }

            const currentRoute = Routes.find(r => router.route.includes(r.path) || r.path.includes(router.route))

            if (!currentRoute.roles.includes(auth.role)) {
                toast.error('Нет доступа')
                router.push({
                    pathname: '/401'
                });
            } else {
                setChecked(true);
            }
        },
        [router.isReady]
    );

    if (!checked) {
        return null;
    }

    // If got here, it means that the redirect did not occur, and that tells us that the user is
    // authenticated / authorized.

    return <>{children}</>;
};
