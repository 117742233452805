import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/use-auth';
import {RolesGuard} from "../../hocs/roles-guard";
import { Roles } from "@app/types/user"

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState(false);

  useEffect(
    () => {
      const enableRoles = [Roles.ADMIN, Roles.MANAGER]

      if (!router.isReady) {
        return;
      }

      if (!auth.isAuthenticated || !enableRoles.includes(auth.role)) {
        router.push({
          pathname: '/login',
          query: { returnUrl: router.asPath }
        });
      } else {
        setChecked(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady, auth.isAuthenticated, auth.role]
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return (
      <RolesGuard>
          {children}
      </RolesGuard>
  )
};

AuthGuard.propTypes = {
  children: PropTypes.node
};
